import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';



import weddingImg1 from '../../reassets/Images/wedding/1.webp';
import weddingImg2 from '../../reassets/Images/wedding/6.webp';
import weddingImg3 from '../../reassets/Images/wedding/_DSC7250.webp';

import preWeddingImg1 from '../../reassets/Images/pre-wedding/1.webp';
import preWeddingImg2 from '../../reassets/Images/service/fashion.jpg';
import preWeddingImg3 from '../../reassets/Images/pre-wedding/3.webp';

import fashionImg1 from '../../reassets/Images/fashion/RANA0006.webp';
import fashionImg2 from '../../reassets/Images/fashion/RANA0425-cropped.jpg';
import fashionImg3 from '../../reassets/Images/fashion/RANA0227.webp';

import productImg1 from '../../reassets/Images/product/RANA0416.webp';
import productImg2 from '../../reassets/Images/product/RANA0350-cropped.jpg';
import productImg3 from '../../reassets/Images/product/RANA0001.webp';

import ecommImg1 from '../../reassets/Images/ecommerce/3K8A7731.webp';
import ecommImg2 from '../../reassets/Images/ecommerce/RANA0019-cropped.jpg';
import ecommImg3 from '../../reassets/Images/ecommerce/_K8A8702.webp';



const Herosection = () => {


    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        {
            img1: weddingImg1,
            img2: weddingImg2,
            img3: weddingImg3,
            title: 'Wedding',
            description: 'Meet the Exciting team for your wedding day! From HUDE PRODUCTION you will get the memorable moments captured.',
            pageLink:'/services/wedding'
        },
        {
            img1: fashionImg1,
            img2: fashionImg2,
            img3: fashionImg3,
            title: 'Fashion',
            description: 'Meet the Exciting team for your Fashion Photography From HUDE PRODUCTION you will get the memorable moments captured.',
            pageLink:'/services/fashion'
        },
        {
            img1: productImg1,
            img2: productImg2,
            img3: productImg3,
            title: 'Product',
            description: 'Meet the Exciting team for your Fashion Photography From HUDE PRODUCTION you will get the memorable moments captured.',
            pageLink:'/services/product'
        },
        {
            img1: preWeddingImg1,
            img2: preWeddingImg2,
            img3: preWeddingImg3,
            title: 'Pre Wedding',
            description: 'Meet the Exciting team for your pre wedding ! From HUDE PRODUCTION you will get the memorable moments captured.',
            pageLink:'/services/pre-wedding'
        },
        {
            img1: ecommImg1,
            img2: ecommImg2,
            img3: ecommImg3,
            title: 'E commerce',
            description: 'Meet the Exciting team for your Fashion Photography From HUDE PRODUCTION you will get the memorable moments captured.',
            pageLink:'/services/ecommerce'
        }
    ];

    // Automatically change slide every second
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 20000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [slides.length]);




    return (
        <>
            <section className='slider-wrapper py-4 mb-0'>
                <div className='container'>
                    <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            {slides.map((slide, index) => (
                                <div
                                    key={index}
                                    className={`carousel-item ${index === currentSlide ? 'active' : ''}`}
                                >
                                    <div className="row">
                                        <div className="col-md-5 animate fadeInRight one">
                                            <div
                                                className="slider-img slide-left"
                                                style={{ background: `url(${slide.img1}) #eee` }}
                                            ></div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="row">
                                                <div className="col-md-12 animate fadeInRight two">
                                                    <div
                                                        className="slider-img2 slide-left"
                                                        style={{ background: `url(${slide.img2}) #eee` }}
                                                    ></div>
                                                </div>
                                                <div className="col-md-5 animate fadeInRight three">
                                                    <div className="slider-img3 slide-left">
                                                        <h5>{slide.title}</h5>
                                                        <p>{slide.description}</p>
                                                        <Link to={slide.pageLink}>View More</Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-7 animate fadeInRight four">
                                                    <div
                                                        className="slider-img4 slide-left"
                                                        style={{ background: `url(${slide.img3}) #eee` }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button> */}
                </div>

            </section>
        </>
    )
}

export default Herosection