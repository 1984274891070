import React from 'react'

import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Link } from 'react-router-dom';
import Herosection from '../components/common/Herosection';
import BestCollections from '../components/common/BestCollections';
import Features from '../components/common/Features';
import ProjectGallery from '../components/common/ProjectGallery';
import BrandTeaser from '../components/common/BrandTeaser';
import ActionBanner from '../components/common/ActionBanner';

//wedding
import weddingImg1 from '../reassets/Images/wedding/1.webp';
import weddingImg2 from '../reassets/Images/wedding/2.webp';
import weddingImg3 from '../reassets/Images/wedding/3.webp';


//prewedding
import preweddingImg1 from '../reassets/Images/pre-wedding/1.webp';
import preweddingImg2 from '../reassets/Images/pre-wedding/2.webp';
import preweddingImg3 from '../reassets/Images/pre-wedding/3.webp';


import fashionImg1 from '../reassets/Images/fashion/RANA0006.webp';
import fashionImg2 from '../reassets/Images/fashion/RANA0425-cropped.jpg';
import fashionImg3 from '../reassets/Images/fashion/RANA0227.webp';

import productImg1 from '../reassets/Images/product/RANA0416.webp';
import productImg2 from '../reassets/Images/product/RANA0350-cropped.jpg';
import productImg3 from '../reassets/Images/product/RANA0001.webp';

import ecommImg1 from '../reassets/Images/ecommerce/3K8A7731.webp';
import ecommImg2 from '../reassets/Images/ecommerce/_K8A8786.webp';
import ecommImg3 from '../reassets/Images/ecommerce/_K8A8702.webp';






const Home = () => {

  const imageItems = [
    fashionImg1,
    fashionImg2,
    fashionImg3,
    productImg1,
    productImg2,
    productImg3,
    ecommImg1,
    ecommImg2,
    ecommImg3,
    weddingImg1,
    weddingImg2,
    weddingImg3,
    preweddingImg1,
    preweddingImg2,
    preweddingImg3,


  ];

  return (
    <>
      <BrandTeaser />
      <Herosection />
      <BestCollections />
      <Features />
      <ProjectGallery images={imageItems} mediaOnly={false} />
      <ActionBanner />
      {/* 
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <InstagramEmbed url="https://www.instagram.com/hude_production/" width={'100%'} maxItemsPerColumn={6} />
      </div> */}
    </>

  )


}

export default Home;
