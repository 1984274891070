import axios from "axios";
import { LMS_URL } from "../config";

export const sendMail = async(formInfo) =>{
    try {
        const axiosRes = await axios({
          method: "POST",
          //headers: { 'x-access-token': localStorage.getItem('token') },
          url: `${LMS_URL}/api/v1/service/client/CL1716_HP_2024?method=direct`,
          data: formInfo
        });
        console.log("send_mail [SUCCESS]", axiosRes.data);
        return axiosRes.data;
      } catch (err) {
        console.log("send_mail [ERROR]", err);
        return err;
      }
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}