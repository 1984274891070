import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { capitalizeFirstLetter } from '../helper/commonHelper';




//images import

import aboutMeBg from '../reassets/backgrounds/bg-about-me.jpg';
import ProjectGallery from '../components/common/ProjectGallery';
import EnquiryModal from '../components/modal/EnquiryModal';

//wedding
import weddingImg1 from '../reassets/Images/wedding/1.webp';
import weddingImg2 from '../reassets/Images/wedding/7.webp';
import weddingImg3 from '../reassets/Images/wedding/3.webp';
import weddingImg4 from '../reassets/Images/wedding/_DSC8055.webp';
import weddingImg5 from '../reassets/Images/wedding/_DSC7250.webp';
import weddingImg6 from '../reassets/Images/wedding/_DSC7693.webp';
import weddingImg7 from '../reassets/Images/wedding/_DSC7294.webp';
import weddingImg8 from '../reassets/Images/wedding/6.webp';
import weddingImg9 from '../reassets/Images/wedding/2.webp';




//prewedding
import preweddingImg1 from '../reassets/Images/pre-wedding/1.webp';
import preweddingImg2 from '../reassets/Images/pre-wedding/2.webp';
import preweddingImg3 from '../reassets/Images/pre-wedding/3.webp';
import preweddingImg4 from '../reassets/Images/pre-wedding/1.webp';
import preweddingImg5 from '../reassets/Images/pre-wedding/2.webp';
import preweddingImg6 from '../reassets/Images/pre-wedding/3.webp';
import preweddingImg7 from '../reassets/Images/pre-wedding/1.webp';
import preweddingImg8 from '../reassets/Images/pre-wedding/2.webp';
import preweddingImg9 from '../reassets/Images/pre-wedding/3.webp';


import fashionImg1 from '../reassets/Images/fashion/RANA0006.webp';
import fashionImg2 from '../reassets/Images/fashion/RANA0425-cropped.jpg';
import fashionImg3 from '../reassets/Images/fashion/RANA0227.webp';
import fashionImg4 from '../reassets/Images/fashion/RANA0006.webp';
import fashionImg5 from '../reassets/Images/fashion/RANA0425-cropped.jpg';
import fashionImg6 from '../reassets/Images/fashion/RANA0227.webp';
import fashionImg7 from '../reassets/Images/fashion/RANA0006.webp';
import fashionImg8 from '../reassets/Images/fashion/RANA0425-cropped.jpg';
import fashionImg9 from '../reassets/Images/fashion/RANA0227.webp';

import productImg1 from '../reassets/Images/product/RANA0416.webp';
import productImg2 from '../reassets/Images/product/RANA0350-cropped.jpg';
import productImg3 from '../reassets/Images/product/RANA0001.webp';
import productImg4 from '../reassets/Images/product/RANA0416.webp';
import productImg5 from '../reassets/Images/product/RANA0350-cropped.jpg';
import productImg6 from '../reassets/Images/product/RANA0001.webp';
import productImg7 from '../reassets/Images/product/RANA0416.webp';
import productImg8 from '../reassets/Images/product/RANA0350-cropped.jpg';
import productImg9 from '../reassets/Images/product/RANA0001.webp';

import ecommImg1 from '../reassets/Images/ecommerce/3K8A7731.webp';
import ecommImg2 from '../reassets/Images/ecommerce/_K8A8786.webp';
import ecommImg3 from '../reassets/Images/ecommerce/_K8A8702.webp';
import ecommImg4 from '../reassets/Images/ecommerce/3K8A7731.webp';
import ecommImg5 from '../reassets/Images/ecommerce/_K8A8786.webp';
import ecommImg6 from '../reassets/Images/ecommerce/_K8A8702.webp';
import ecommImg7 from '../reassets/Images/ecommerce/3K8A7731.webp';
import ecommImg8 from '../reassets/Images/ecommerce/_K8A8786.webp';
import ecommImg9 from '../reassets/Images/ecommerce/_K8A8702.webp';







const Servicedetails = () => {

  let { service_name } = useParams();
  const [show, setShow] = useState(false);
  const toggleModal = () => {
    show === true ? setShow(false) : setShow(true);
  }

  const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000'
  }

  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '500px',
    width: '1550px'
  }



  const getImageItems = () => {
    const weddingImages = [
      weddingImg1,
      weddingImg2,
      weddingImg3,
      weddingImg4,
      weddingImg5,
      weddingImg6,
      weddingImg7,
      weddingImg8,
      weddingImg9,

    ];

    const preWeddingImages = [
      preweddingImg1,
      preweddingImg2,
      preweddingImg3,
      preweddingImg4,
      preweddingImg5,
      preweddingImg6,
      preweddingImg7,
      preweddingImg8,
      preweddingImg9,
    ];

    const fashionImages = [
      fashionImg1,
      fashionImg2,
      fashionImg3,
      fashionImg4,
      fashionImg5,
      fashionImg6,      
      fashionImg7,
      fashionImg8,
      fashionImg9,
    ];

    const ecommImages = [
      ecommImg1,
      ecommImg2,
      ecommImg3,
      ecommImg4,
      ecommImg5,
      ecommImg6,
      ecommImg7,
      ecommImg8,
      ecommImg9,

    ];

    const productImages = [
      productImg1,
      productImg2,
      productImg3,
      productImg4,
      productImg5,
      productImg6,
      productImg7,
      productImg8,
      productImg9,

    ];

    const allImages = [
      fashionImg1,
      fashionImg2,
      fashionImg3,
      productImg1,
      productImg2,
      productImg3,
      ecommImg1,
      ecommImg2,
      ecommImg3,
      weddingImg1,
      weddingImg2,
      weddingImg3,
      preweddingImg1,
      preweddingImg2,
      preweddingImg3,
    ];


    if (service_name === 'wedding') {
      return weddingImages;
    }
    else if (service_name === 'pre-wedding') {
      return preWeddingImages;
    }
    else if (service_name === 'e-commerce') {
      return ecommImages;
    }
    else if (service_name === 'fashion') {
      return fashionImages;
    }
    else if (service_name === 'product') {
      return productImages;
    }
    else if (service_name === 'editing') {
      return;
    } else {
      return allImages;
    }
  }



  return (
    <>
      <div className="slide-container">
        <Slide>
          {getImageItems().map((slideImage, index) => (
            <div key={index}>
              <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage})` }}>
                {/* <span style={spanStyle}>{slideImage.caption}</span> */}
              </div>
            </div>
          ))}
        </Slide>
      </div>


      <section className='service-details' style={{ background: `url(${aboutMeBg})`, height: 'auto', backgroundSize: 'cover', backgroundPosition: 'center -260px' }}>
        <div className='container'>

          <div className='row align-items-center'>
            <div className='col-md-5 py-5'>
              {/* <small className='text-white'>service</small> */}
              <h4 className='text-white'>{capitalizeFirstLetter(service_name)}</h4>
              <p className='text-white'>{""}</p>
            </div>
            <div className='col-md-7'>
              <div className='float-right'>
                <span>Starting From</span>
                <p>₹ XX,XXX</p>
                <button className='btn btn-outline-light' onClick={toggleModal}>Book Now</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProjectGallery images={getImageItems()} mediaOnly={true} />
      <EnquiryModal showModal={show} handleToggleModal={toggleModal} />

    </>
  )
}

export default Servicedetails;
