import React from 'react';
import hudeLogo from '../reassets/Images/logo/logo.png';
import phoneIcon from '../reassets/Images/icons/phone.png'
import emailIcon from '../reassets/Images/icons/email.png'
import { Link } from 'react-router-dom';

const footer = () => {
  return (
    <>
      <section className='py-4 bg-dark'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <h2 className='text-white '>Pages</h2>
              <div className='text-white'>
                <li><Link className='text-white text-decoration-none' to='/'>Home</Link></li>
                <li><Link className='text-white text-decoration-none' to='/about'>About</Link></li>
                <li><Link className='text-white text-decoration-none' to='/services'>Services</Link></li>
              </div>            </div>
            <div className='col-md-4'>
              <h2 className='text-white'>We Offer</h2>
              <div className='text-white'>
                <li><Link className='text-white text-decoration-none' to='/services/wedding'>Wedding Photography/Videography</Link></li>
                <li><Link className='text-white text-decoration-none' to='/services/pre-wedding'>Pre Wedding Photography/Videography</Link></li>
                <li><Link className='text-white text-decoration-none' to='/services/product'>Product Photography</Link></li>
                <li><Link className='text-white text-decoration-none' to='/services/fashion'>Fashion Photography</Link></li>
                <li><Link className='text-white text-decoration-none' to='/services/e-commerce'>E-commerce Photography</Link></li>
                <li><Link className='text-white text-decoration-none' to='/services/editing'>Photo & Video Editing </Link></li>
              </div>


            </div>
            <div className='col-md-4'>
              <h2 className='text-white'>Contact</h2>
              <p className='text-white'><span>  <img src={phoneIcon} alt="" style={{ width: '25px', height: '25px' }} /> : +91 9818701404</span></p>
              <p className='text-white'><span> <img src={emailIcon} alt="" style={{ width: '20px' }} /> : info@hudeproduction.com</span></p>

            </div>
          </div>
          <hr/>
          <div className='last-div-footer row'>
            <div className='col-md-12'>
              <small className='text-white'>© {new Date().getFullYear()} Hude Production. All Rights Reserved.</small>
            </div>

          </div>

        </div>


      </section>




    </>
  )
}

export default footer;
