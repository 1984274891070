import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import EnquiryModal from './modal/EnquiryModal';

import hudeLogo from '../reassets/Images/logo/logo.png';
import phoneIcon from '../reassets/Images/icons/phone.png'
import emailIcon from '../reassets/Images/icons/email.png'



const Header = () => {

  const [show, setShow] = useState(false);
  const toggleModal = () => {
    show === true ? setShow(false) : setShow(true);
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-0">
        <div className="container-xl">

          <Link className="navbar-brand" to="/">
            <img src={hudeLogo} className="h-8" alt="..." width="100%" />
          </Link>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarCollapse">

            <div className="navbar-nav mx-lg-auto">
              <Link className="nav-item nav-link" to="/" aria-current="page">Home</Link>
              <Link className="nav-item nav-link" to="/about">About</Link>
              <Link className="nav-item nav-link" to="/services">Services</Link>
              {/* <Link className="nav-item nav-link" to="/servicedetails/3434">Portfolio</Link> */}
            </div>



            <div className="d-flex align-items-lg-center mt-3 mt-lg-0">
              <Link className='d-flex px-3 gap-2 text-decoration-none'>
                <img src={phoneIcon} alt="" style={{ width: '35px', height: '35px' }} />
                <div>
                  <h5 className='text-white mb-0' style={{ fontSize: '14px' }}>Call Us</h5>
                  <span className='text-white' style={{ fontSize: '13px' }}>+91 9818701404</span>
                </div>
              </Link>

              <Link className='d-flex px-3 gap-2 text-decoration-none'>
                <img src={emailIcon} alt="" style={{ width: '40px' }} />
                <div>
                  <h5 className='text-white mb-0' style={{ fontSize: '14px' }}>Mail Us</h5>
                  <span className='text-white' style={{ fontSize: '13px' }}>info@hudeproduction.com</span>
                </div>
              </Link>

              <Button className="btn btn-sm btn-light w-full w-lg-auto" style={{
                height: "2.5rem ",
                fontWeight: "bold",
                fontSize: "1rem",
                textTransform: "uppercase"
              }} onClick={toggleModal}>Book Now</Button>
            </div>
          </div>
        </div>
      </nav >

      <EnquiryModal showModal={show} handleToggleModal={toggleModal} />


    </>
  )
}

export default Header;
