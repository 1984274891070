import React from 'react';
import teaserVideo from '../../reassets/Videos/FinalComphude.mp4';

const BrandTeaser = () => {
    return (

        <>
            <section className="outter hero-video">
                <section className="video-container bg-dark">
                    <video src={teaserVideo} loop autoPlay muted ></video>
                </section>
            </section>
        </>
    )
}

export default BrandTeaser